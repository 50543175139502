import React from 'react';
import moment from 'moment';

import { Link, useNavigate } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function EventsList({ data }) {
  let navigate = useNavigate();

  const events = React.useMemo(() => data?.map(x => ({
    ...x,
    start: moment(x.start_time / 1000),
    end: x.end_time ? moment(x.end_time / 1000) : undefined,
    duration: moment.duration(
      (x.end_time ? (x.end_time / 1000) : moment().valueOf())
        - (x.start_time / 1000)
    )
  })), [data]);

  return <>
    <BootstrapTable
      keyField='uuid'
      data={ events }
      columns={[{
        dataField: 'uuid',
        text: 'Event',
        formatter: (cell) => (
          <code>{cell.substring(0,8)}</code>
        )
      }, {
        dataField: 'status',
        text: 'Status',
        formatter: (cell) => {
          switch(cell) {
            case "running":
              return (
                <Badge pill bg="warning">
                  In Progress
                </Badge>
              );
            case "complete":
              return (
                <Badge pill bg="success">
                  Complete
                </Badge>
              );
            default:
              return (
                <Badge pill bg="secondary">
                  {cell}
                </Badge>
              );
          }
        }
      }, {
        dataField: 'type',
        text: 'Type',
        formatter: (cell) => {
          switch(cell) {
            case "device_triggered":
              return (
                <Badge pill bg="secondary">
                  Device Triggered
                </Badge>
              );
            default:
              return (
                <Badge pill bg="secondary">
                  {cell}
                </Badge>
              );
          }
        }
      }, {
        dataField: 'duration',
        text: 'Duration',
        formatter: (cell) => cell.humanize()
      }, {
        dataField: 'start',
        text: 'Start',
        sort: true,
        formatter: (cell) => (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip> {cell.fromNow()} </Tooltip>}
          >
            <span>{cell.format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
          </OverlayTrigger>
        )
      }, {
        dataField: 'end',
        text: 'End',
        sort: true,
        formatter: (cell) => cell ? (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip> {cell.fromNow()} </Tooltip>}
          >
            <span>{cell.format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
          </OverlayTrigger>
        ) : "In Progress"
      }]}
      pagination={ paginationFactory({
        showTotal: true,
        paginationTotalRenderer: (from, to, size) => (
          <span className="react-bootstrap-table-pagination-total">
            {" "} Showing { from } to { to } of { size } events
          </span>
        ),
        sizePerPageList: [{
          text: '10', value: 10
        }, {
          text: '25', value: 25
        }, {
          text: '50', value: 50
        }, {
          text: '100', value: 100
        }]
      }) }
      defaultSorted ={[{
        dataField: 'start',
        order: 'desc'
      }]}
      rowStyle = {{
        cursor: 'pointer'
      }}
      rowEvents = {{
        onClick: (e, row) => {
          navigate(`/events/${row.uuid}`);
        }
      }}
      striped
      hover
      wrapperClasses="table-responsive"
      />
    </>;
}
