import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useUser } from "../data/use-user.js";

export default function ProtectedPage({ element }) {
  const { user, loggedOut, login } = useUser();

  if(loggedOut || user.exp < (Date.now() / 1000)) {
    login(window.location.pathname);

    return "Redirecting to login...";
  }

  return element;
};

