import React from 'react';
import moment from 'moment';

import { Link, useNavigate } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function DevicesList({ data }) {
  let navigate = useNavigate();

  const devices = React.useMemo(() => data?.map(x => ({
    ...x,
    last_online: x.last_online ? moment(x.last_online / 1000) : undefined,
  })), [data]);

  return <>
    <BootstrapTable
      keyField='uuid'
      data={ devices }
      columns={[{
        dataField: 'uuid',
        text: 'Device',
        formatter: (cell) => (
          <code>{cell.substring(0,8)}</code>
        )
      }, {
        dataField: 'name',
        text: 'Name',
        formatter: (cell) => (
          <span>{ cell || "—" }</span>
        )
      }, {
        dataField: 'last_online',
        text: 'Last Online',
        sort: true,
        formatter: (cell) => cell ? (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip> {cell.format("dddd, MMMM Do YYYY, h:mm:ss a")} </Tooltip>}
          >
            <span>{cell.fromNow()}</span>
          </OverlayTrigger>
        ) : "—"
      }, {
        dataField: 'status.voltage',
        text: 'Voltage',
        formatter: (cell) => (
          <span>{ cell ? cell.toFixed(1) + " V" : "—" }</span>
        )
      }, {
        dataField: 'status.frequency',
        text: 'Frequency',
        formatter: (cell) => (
          <span>{ cell ? cell.toFixed(1) + " Hz" : "—" }</span>
        )
      }]}
      pagination={ paginationFactory({
        showTotal: true,
        paginationTotalRenderer: (from, to, size) => (
          <span className="react-bootstrap-table-pagination-total">
            {" "} Showing { from } to { to } of { size } devices
          </span>
        ),
        sizePerPageList: [{
          text: '10', value: 10
        }, {
          text: '25', value: 25
        }, {
          text: '50', value: 50
        }, {
          text: '100', value: 100
        }]
      }) }
      defaultSorted ={[{
        dataField: 'last_online',
        order: 'desc'
      }]}
      rowStyle = {{
        cursor: 'pointer'
      }}
      rowEvents = {{
        onClick: (e, row) => {
          navigate(`/devices/${row.uuid}`);
        }
      }}
      striped
      hover
      wrapperClasses="table-responsive"
      />
    </>;
}
