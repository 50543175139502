import React from 'react';
import useSWR from 'swr';

import { API_URI } from "../constants.js";

import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Table, Badge, Nav, Tabs, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';

import Map from '../components/map.js';
import EventsList from '../components/eventslist.js';

const fetcher = (url) => fetch(url, { credentials: 'include' }).then((res) => res.json());

export default function Events() {
  let navigate = useNavigate();

  const { data, error } = useSWR(
    `${API_URI}/v1/events`,
    fetcher
  );

  if (error) return "An error has occurred.";
  if (!data) return "Loading...";

  return <Container>
    <Row>
      <Col>
        <Tab.Container defaultActiveKey="map">
          <Card>
            <Card.Header>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="map" style={{ cursor: 'pointer' }}>Map</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="status" style={{ cursor: 'pointer' }}>Status</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="map">
                  <Map
                    style="mapbox://styles/mapbox/streets-v9"
                    containerStyle={{
                      height: '50vh',
                    }}
                    center={[-122.063313, 36.998345]}/>
                </Tab.Pane>
                <Tab.Pane eventKey="status">
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Card>
        </Tab.Container>
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <Card>
          <Card.Body>
            <EventsList data={ data } />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>;
}
