import React from "react";
import useSWR from "swr";
import moment from 'moment';
import _ from 'lodash';

import { API_URI } from "../constants.js";

import DropdownTreeSelect from "react-dropdown-tree-select";
import "./eventcharts.css";
import 'react-dropdown-tree-select/dist/styles.css'

import Plotly from 'plotly.js-basic-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
const Plot = createPlotlyComponent(Plotly);

const fetcher = (url) => fetch(url, { credentials: 'include' }).then((res) => res.json());

export function EventPlot({ eventId, streams }) {
  const { data, error } = useSWR(
    `${API_URI}/v1/events/${eventId}/data?streams=${streams.join(",")}`,
    fetcher
  );

  const series = React.useMemo(() => {
      if(!data) {
        return {};
      }

      return Object.keys(data).map(stream => {
        const axes = _.unzip(data[stream])

        return {
          x: axes[0]?.map(x => moment(x / 1000).format("YYYY-MM-DD HH:mm:ss.SSS") + String(Math.round(x % 1000)).padStart(3, '0')),
          y: axes[1],
          type: 'line',
        };
      });
    },
    [data]
  );

  if (error) return "An error has occurred.";
  if (!data) return "Loading...";

  return <Plot
      data={ series }
      useResizeHandler={true}
      style={{width: "100%", height: "100%"}}
    />;
}

export default function EventCharts({ eventId }) {
  const [selectedStreams, setSelectedStreams] = React.useState([]);

  const { data, error } = useSWR(
    `${API_URI}/v1/events/${eventId}/streams?expanded=true`,
    fetcher
  );

  const dropdownData = React.useMemo(() => {
      if(!data) {
        return [];
      }

      let devices = {};

      for(const stream of data) {
        // Ensure the device exists
        if(devices[stream.device] === undefined) {
          devices[stream.device] = {};
        }

        // Ensure the phase exists
        if(devices[stream.device][stream.phase] === undefined) {
          devices[stream.device][stream.phase] = [];
        }

        devices[stream.device][stream.phase].push(stream);
      }

      return Object.keys(devices).map(device => {
        let deviceStreams = [];

        let children = Object.keys(devices[device]).map(phase => {
          let phaseStreams = [];

          let children = devices[device][phase].map(stream => {
            deviceStreams.push(stream.uuid);
            phaseStreams.push(stream.uuid);

            return {
              label: stream.type,
              value: [ stream.uuid ],
            };
          });

          return {
            label: `Phase ${phase.substring(0,8)}`,
            value: phaseStreams,
            children
          };
        });

        return {
          label: `Device ${device.substring(0,8)}`,
          value: deviceStreams,
          children
        };
      });
    },
    [data]
  );

  const dropdown = React.useMemo(() => {
      if(!dropdownData) {
        return undefined;
      }

      return <DropdownTreeSelect data={dropdownData} onChange={(_, selectedNodes) => {
        setSelectedStreams(selectedNodes.map(x => x.value).flat());
      }} className="bootstrap-demo" style={{".tag-list": { "display": "none" }}} showPartiallySelected keepTreeOnSearch keepOpenOnSelect />;
    },
    [dropdownData]
  );

  if (error) return "An error has occurred.";
  if (!data) return "Loading...";

  return <>
    {dropdown}
    <EventPlot eventId={eventId} streams={selectedStreams}/>
  </>;
}
