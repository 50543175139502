import Map from 'react-map-gl';

import { MAPBOX_ACCESS_TOKEN } from '../constants';

export default (props) => {
  return <Map
    initialViewState={{
      longitude: -100,
      latitude: 40,
      zoom: 3.5
    }}
    style={{height: "50vh"}}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
  />
};
