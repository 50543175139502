import * as React from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';

import { API_URI, CONTACT_EMAIL } from "../constants.js";

import jwt_decode from "jwt-decode";

const authContext = React.createContext();

export function useUser() {
  const navigate = useNavigate();

  const [ cookies, setCookie, removeCookie ] = useCookies(["auth0-id-token"]);


  const loggedOut = cookies["auth0-id-token"] === undefined;

  const data = loggedOut ? undefined : jwt_decode(cookies["auth0-id-token"]);

  return {
    loggedOut,
    user: data,
    login(uri) {
      setCookie("auth0-redirect-uri", `${window.location.origin}${uri}`, { path: '/' });

      window.location.replace(`${API_URI}/oauth/login`);
    },
    logout(uri) {
      setCookie("auth0-redirect-uri", uri ? `${window.location.origin}${uri}`: window.location.origin, { path: '/' });

      window.location.replace(`${API_URI}/oauth/logout`);
    }
  };
}

export function AuthProvider({ children }) {
  const auth = useUser();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}
