import React from "react";
import useSWR from "swr";
import moment from 'moment';

import { API_URI } from "../constants.js";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { Card, Table, Badge, Nav, Tabs, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';

const fetcher = (url) => fetch(url, { credentials: 'include' }).then((res) => res.json());

export default function EventLog({ eventId }) {
  const { data, error } = useSWR(
    `${API_URI}/v1/events/${eventId}/logs`,
    fetcher
  );

  const logs = React.useMemo(() => data?.map(x => ({
    ...x,
    time: moment(x.time / 1000),
  })), [data]);

  if (error) return "An error has occurred.";
  if (!data) return "Loading...";

  return <>
    <BootstrapTable
      keyField='time'
      data={ logs }
      columns={[{
          dataField: 'time',
          text: 'Time',
          sort: true,
          formatter: (cell) => (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip> {cell.fromNow()} </Tooltip>}
            >
              <p>{cell.format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
            </OverlayTrigger>
          )
        }, {
          dataField: 'type',
          text: 'Type',
          formatter: (cell) => {
            switch(cell) {
              case "phase_trigger":
                return (
                  <Badge pill bg="warning">
                    Trigger
                  </Badge>
                );
              case "phase_untrigger":
                return (
                  <Badge pill bg="warning">
                    Untrigger
                  </Badge>
                );
              default:
                return (
                  <Badge pill bg="secondary">
                    {cell}
                  </Badge>
                );
            }
          }
        }, {
          dataField: 'data',
          text: 'Data',
          formatter: (cell) => (<code> {JSON.stringify(cell)} </code>)
        },
      ]}
      pagination={ paginationFactory({
        showTotal: true,
        paginationTotalRenderer: (from, to, size) => (
          <span className="react-bootstrap-table-pagination-total">
            {" "} Showing { from } to { to } of { size } Event Messages
          </span>
        ),
        sizePerPageList: [{
          text: '10', value: 10
        }, {
          text: '25', value: 25
        }, {
          text: '50', value: 50
        }, {
          text: '100', value: 100
        }]
      }) }
      defaultSorted ={[{
        dataField: 'time',
        order: 'asc'
      }]}
      striped
      hover
      wrapperClasses="table-responsive"
      />
  </>;
}
