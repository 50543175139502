import { Link } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown, Image } from 'react-bootstrap';

import { useUser } from "../data/use-user.js";

export default () => {
  const { user, loggedOut, login, logout } = useUser();

  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">EADS</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="devices">Devices</Nav.Link>
            <Nav.Link as={Link} to="events">Events</Nav.Link>
          </Nav>
          <Nav>
            {
              loggedOut ?
                <Nav.Link onClick={ () => login("/devices") }>Login</Nav.Link>
              :
              <NavDropdown title={
                    <span>
                        <Image
                            src={user.picture} 
                            alt="User pic"
                            roundedCircle
                            style={{ width: '30px', marginRight: '5px' }}
                        />

                        {user.name}
                    </span>
                  }
                  style={{ padding: '0px' }}>
                <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={ () => logout() }>Log Out</NavDropdown.Item>
              </NavDropdown>
            }
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};
