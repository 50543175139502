import React from "react";
import useSWR from "swr";
import moment from 'moment';

import { API_URI } from "../constants.js";
import { useUser } from "../data/use-user.js";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Table, Badge, Nav, Tabs, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';

import EventLog from '../components/eventlog.js';
import EventCharts from '../components/eventcharts.js';
import EventMap from '../components/EventMap.js';

const fetcher = (url) => fetch(url, { credentials: 'include' }).then((res) => res.json());

const renderStatus = (status) => {
  switch(status) {
    case "running":
      return (
        <Badge pill bg="warning">
          In Progress
        </Badge>
      );
    case "complete":
      return (
        <Badge pill bg="success">
          Complete
        </Badge>
      );
    default:
      return (
        <Badge pill bg="secondary">
          {status}
        </Badge>
      );
  }
};

export default function Event() {
  let params = useParams();
  const { user  } = useUser();

  const { data, error } = useSWR(
    `${API_URI}/v1/events/${params.eventId}`,
    fetcher
  );

  if (error) return "An error has occurred.";
  if (!data) return "Loading...";

  // TODO: Memoize
  const start = moment(data.start_time / 1000);
  const end = moment(data.start_time / 1000);
  const duration = moment.duration((data.end_time - data.start_time) / 1000);

  return <Container>
    <Row>
      <Col>
        <Card>
          <Card.Header> Event <code>{ data.uuid.substring(0,8) }</code> </Card.Header>
          <Card.Body>
            <div>
              <p>Status: { renderStatus(data.status) }</p>
              <p>Duration: { duration.humanize() }</p>
              <p>Start: { start.fromNow() }</p>
              <p>End: { end.fromNow() }</p>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <EventMap eventId={params.eventId}/>
        </Card>
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <Tab.Container defaultActiveKey="charts">
          <Card>
            <Card.Header>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="charts" style={{ cursor: 'pointer' }}>Charts</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="log" style={{ cursor: 'pointer' }}>Log</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="export" style={{ cursor: 'pointer' }}>Export</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="charts">
                  <Container>
                    <Row>
                      <Col style={{ height: "70vh", width: "100%" }}>
                        <EventCharts eventId={params.eventId} />
                      </Col>
                    </Row>
                  </Container>
                </Tab.Pane>
                <Tab.Pane eventKey="log">
                  <EventLog eventId={params.eventId} />
                </Tab.Pane>
                <Tab.Pane eventKey="export">
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Card>
        </Tab.Container>
      </Col>
    </Row>
  </Container>;
}
