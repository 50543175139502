import { Link, useParams, useSearchParams } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button, ProgressBar, Spinner } from 'react-bootstrap';

export default function Main() {
  return <>
    <Container style={{textAlign: "center"}}>
      <Row>
        <Col>
          <h2> Electric Anomaly Detection System </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <p> EADS is a network of intelligent sensors for monitoring the power grid on the consumer's side. EADS can automatically detect anomalies and provide data which can be used to determine the type, cause, and extent of service disruption. EADS also acts as a data collection platform, enabling further research into power grid resiliency and reliability. </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button as={Link} to={"/devices/"}>Go To Devices</Button>
        </Col>
      </Row>
    </Container>
  </>;
}
