import React from "react";
import useSWR from "swr";
import moment from 'moment';
import _ from 'lodash';

import { API_URI } from "../constants.js";

import ReactSpeedometer from 'react-d3-speedometer';

import Plotly from 'plotly.js-basic-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
const Plot = createPlotlyComponent(Plotly);

import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Nav, Tab, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

import DeviceMap from '../components/devicemap.js';
import EventsList from '../components/eventslist.js';

const fetcher = (url) => fetch(url, { credentials: 'include' }).then((res) => res.json());

export default function Device() {
  let params = useParams();

  const { data, error } = useSWR(
    `${API_URI}/v1/devices/${params.deviceId}?status=true`,
    fetcher
  );

  const { data: phases, error: phasesError } = useSWR(
    `${API_URI}/v1/devices/${params.deviceId}/phases`,
    fetcher
  );

  const { data: streams, error: streamsError } = useSWR(
    `${API_URI}/v1/devices/${params.deviceId}/streams`,
    fetcher
  );

  const { data: events, error: eventsError } = useSWR(
    `${API_URI}/v1/devices/${params.deviceId}/events`,
    fetcher
  );

  const { data: heartbeat, error: heartbeatError } = useSWR(
    `${API_URI}/v1/devices/${params.deviceId}/heartbeats/latest`,
    fetcher
  );

  // TODO: We need to do this once per phase.
  const phaseInfo = React.useMemo(() => {
      if(!phases || !streams || !heartbeat) {
        return {};
      }

      const freq = streams.find(x => x.type === 'frequency');
      const volt = streams.find(x => x.type === 'voltage_rms');
      const wave = streams.find(x => x.type === 'waveform');

      const waveform = _.unzip(heartbeat[wave.uuid]);

      return phases.map(x => ({
        ...x,
        frequency: {
          time: heartbeat[freq.uuid]?.at(0)?.at(0),
          value: heartbeat[freq.uuid]?.at(0)?.at(1),
        },
        voltage: {
          time: heartbeat[volt.uuid]?.at(0)?.at(0),
          value: heartbeat[volt.uuid]?.at(0)?.at(1),
        },
        waveform: {
          x: waveform[0]?.map(x => moment(x / 1000).format("YYYY-MM-DD HH:mm:ss.SSS") + String(Math.round(x % 1000)).padStart(3, '0')),
          y: waveform[1],
        }
      }));
    },
    [phases, streams, heartbeat]
  );
  const lastOnline = React.useMemo(() => {
      if(!data) {
        return undefined;
      }

      return data.last_online ? moment(data.last_online / 1000) : undefined;
    },
    [data]
  );

  if (error || phasesError || streamsError || eventsError || heartbeatError) return "An error has occurred.";
  if (!data || !phases || !streams || !events || !heartbeat) return "Loading...";

  return <Container>
    <Row>
      <Col xs={12} md={6}>
        <Card>
          <Card.Header>
            { data.name ? <>
              <span>{ data.name } </span>
              (<code>{ data.uuid.substring(0,8) }</code>)
            </> : <>
              <code>{ data.uuid.substring(0,8) }</code>
            </>}
          </Card.Header>
          <Card.Body>
            <p> Last Online: {' '}
              { lastOnline ?
                  (<OverlayTrigger
                      placement="right"
                      overlay={<Tooltip> {lastOnline.format("dddd, MMMM Do YYYY, h:mm:ss a")} </Tooltip>}
                    >
                      <span>{lastOnline.fromNow()}</span>
                    </OverlayTrigger>
                  ) : (<span> — </span>)
              }
            </p>
            { phaseInfo?.map(phase => (
              <Card key={phase.uuid}>
                <Card.Header> { phase.name ? phase.name : "Phase " + phase.uuid.substring(0,8) } </Card.Header>
                <Card.Body>
                  <Container>
                    <Row>
                      <Col xs={12} md={6} style={{height: "180px"}}>
                        <ReactSpeedometer
                          fluidWidth
                          style={{ margin: "10px" }}
                          minValue={100}
                          maxValue={140}
                          value={Math.min(140, Math.max(100, phase.voltage.value))}
                          customSegmentStops={[100, 110, 115, 125, 130, 140]}
                          labelFontSize={'10px'}
                          valueFormat=".1f"
                          segmentColors={['#d08770', '#ebcb8b', '#a3be8c', '#ebcb8b', '#d08770']}
                          currentValueText={`${phase.voltage.value ? phase.voltage.value.toFixed(1) : "—"} Volts`}
                        />
                      </Col>
                      <Col xs={12} md={6} style={{height: "180px"}}>
                        <ReactSpeedometer
                          fluidWidth
                          minValue={57}
                          maxValue={63}
                          value={Math.min(63, Math.max(57, phase.frequency.value))}
                          customSegmentStops={[57, 58, 59, 59.7, 60.3, 61, 62, 63]}
                          labelFontSize={'10px'}
                          segmentColors={['#bf616a', '#d08770', '#ebcb8b', '#a3be8c', '#ebcb8b', '#d08770', '#bf616a']}
                          valueFormat=".1f"
                          currentValueText={`${phase.frequency.value ? phase.frequency.value.toFixed(1) : "—"} Hz`}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Plot
                        data={[
                          {
                            x: phase.waveform.x,
                            y: phase.waveform.y,
                            type: 'line',
                          },
                        ]}
                        useResizeHandler={true}
                        style={{width: "100%", height: "300px"}}
                        layout={{
                            margin: {
                              autoexpand: false,
                              l: 30,
                              r: 30,
                              t: 0
                            }
                        }}
                      />
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            )) }
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={6}>
        <Card>
          <DeviceMap devices={[ data ]} center={{ longitude: data.longitude, latitude: data.latitude }} />
        </Card>
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <Tab.Container defaultActiveKey="events">
          <Card>
            <Card.Header>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="events" style={{ cursor: 'pointer' }}>Events</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="settings" style={{ cursor: 'pointer' }}>Settings</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="events">
                  <EventsList data={ events } />
                </Tab.Pane>
                <Tab.Pane eventKey="settings">
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Card>
        </Tab.Container>
      </Col>
    </Row>
  </Container>;
}
