import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Nav from './components/nav.js';
import ProtectedPage from './components/protectedpage.js';

import Main from './pages/main.js';
import Devices from './pages/devices.js';
import Device from './pages/device.js';
import Events from './pages/events.js';
import Event from './pages/event.js';
import Register from './pages/register.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Nav />
      <div style={{
        margin: "15vw",
        marginTop: "20px",
        marginBottom: "20px",
        background: "white"
      }}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/devices/:deviceId" element={<Device />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:eventId" element={<Event />} />
          <Route path="/register/:deviceId" element={<ProtectedPage element={<Register />} />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
