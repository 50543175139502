import moment from 'moment';

import Map, {Source, Layer } from 'react-map-gl';

import { MAPBOX_ACCESS_TOKEN } from "../constants.js";
import { Link } from "react-router-dom";
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

// https://stackoverflow.com/a/53739792
function flattenObject(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object' && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}


export default ({ devices, center }) => {
  const mapRef = React.useRef(null);
  const [popupInfo, setPopupInfo] = React.useState(null);

  const geojson = {
    type: 'FeatureCollection',
    features: devices.filter(device => (
      device.longitude != undefined && device.latitude != undefined
    )).map(device => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [device.longitude, device.latitude]
      },
      properties: flattenObject(device)
    }))
  };

  const clusterLayer = {
    id: 'clusters',
    type: 'circle',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
  };

  const clusterCountLayer = {
    id: 'cluster-count',
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12
    }
  };

  const unclusteredPointLayer = {
    id: 'unclustered-point',
    type: 'circle',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': '#11b4da',
      'circle-radius': 10,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff'
    }
  };

  const onClick = event => {
    console.log(event.features);
    const {
      features,
      point: {x, y}
    } = event;

    const hoveredFeature = features && features[0];
    if(!hoveredFeature) {
      return;
    }

    if(hoveredFeature.layer.id === clusterLayer.id) {
      const clusterId = hoveredFeature.properties.cluster_id;

      const mapboxSource = mapRef.current.getSource("data");

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        mapRef.current.easeTo({
          center: hoveredFeature.geometry.coordinates,
          zoom,
          duration: 500
        });
      });
    } else if(hoveredFeature.layer.id === unclusteredPointLayer.id) {
      setPopupInfo(hoveredFeature && {feature: hoveredFeature, x, y});
    }
  };

  const onMoveStart = event => {
    setPopupInfo(null);
  };

  return <Map
    initialViewState={{
      longitude: center?.longitude ? center.longitude : -122.063313,
      latitude: center?.latitude ? center.latitude : 36.998345,
      zoom: 12
    }}
    style={{height: "50vh"}}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
    interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
    ref={mapRef}
    onClick={onClick}
    onMoveStart={onMoveStart}
  >
    <Source id="data" type="geojson" data={geojson} cluster={true} clusterMaxZoom={16} clusterRadius={50}>
      <Layer {...clusterLayer} />
      <Layer {...clusterCountLayer} />
      <Layer {...unclusteredPointLayer} />
    </Source>
      {popupInfo && (
        <Card style={{
          position: "absolute",
          left: popupInfo.x,
          top: popupInfo.y
        }}>
          <Card.Header>
            { popupInfo.feature.properties.name ? <>
              <span>{ popupInfo.feature.properties.name } </span>
              (<code>{ popupInfo.feature.properties.uuid.substring(0,8) }</code>)
            </> : <>
              <code>{ popupInfo.feature.properties.uuid.substring(0,8) }</code>
            </>}
          </Card.Header>
          <Card.Body style={{ padding: "0.5rem" }}>
            <span> Last Online: {' '}
              { popupInfo.feature.properties.last_online
                ? <span>{moment(popupInfo.feature.properties.last_online / 1000).fromNow()}</span>
                : (<span> — </span>)
              }
            </span>
            <br/>
            <span> Voltage: {' '}
              { popupInfo.feature.properties["status.voltage"]
                ? <span>{popupInfo.feature.properties["status.voltage"].toFixed(1) + " V"}</span>
                : (<span> — </span>)
              }
            </span>
            <br/>
            <span> Frequency: {' '}
              { popupInfo.feature.properties["status.frequency"]
                ? <span>{popupInfo.feature.properties["status.frequency"].toFixed(1) + " Hz"}</span>
                : (<span> — </span>)
              }
            </span>
            <br/>
            <div style={{ textAlign: "center" }}>
              <Button as={Link} to={`/devices/${popupInfo.feature.properties.uuid}`} size="sm">Go To Device</Button>
            </div>
          </Card.Body>
        </Card>
      )}
  </Map>
};
