import React from "react";
import useSWR from "swr";
import moment from 'moment';

import { API_URI, MAPBOX_ACCESS_TOKEN } from "../constants.js";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Table, Badge, Nav, Tabs, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';

import Map, {Source, Layer } from 'react-map-gl';

const fetcher = (url) => fetch(url, { credentials: 'include' }).then((res) => res.json());

export default function Event({ eventId, center }) {
  const { data, error } = useSWR(
    `${API_URI}/v1/events/${eventId}/location`,
    fetcher
  );

  const geojson = React.useMemo(() => {
      if(!data) {
        return {};
      }
      return JSON.parse(data.geojson);
    },
    [data]
  );

  if (error) return "An error has occurred.";
  if (!data) return "Loading...";

  return <Map
    initialViewState={{
      longitude: center?.longitude ? center.longitude : -122.063313,
      latitude: center?.latitude ? center.latitude : 36.998345,
      zoom: 12
    }}
    style={{height: "50vh"}}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
  >
    <Source id="my-data" type="geojson" data={geojson}>
      <Layer
        id='data'
        type='fill'
        paint={{
          'fill-color': '#3288bd',
          'fill-opacity': 0.8
        }}
        />
    </Source>
  </Map>;
}
