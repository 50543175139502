import React from "react";
import { useCookies } from "react-cookie";
import useSWR from "swr";

import { API_URI, CONTACT_EMAIL } from "../constants.js";

import { Link, useParams, useSearchParams } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button, ProgressBar, Spinner } from 'react-bootstrap';
import SelectLocation from "../components/selectlocation.js";

const fetcher = (url) => fetch(url, { credentials: 'include' }).then((res) => res.json());

export default function Register() {
  const params = useParams();
  const [cookies, setCookie, removeCookie] = useCookies(["register-token"]);
  const [state, setState] = React.useState("not_started");

  const [validated, setValidated] = React.useState(false);
  const [location, setLocation] = React.useState({
    latitude: undefined,
    longitude: undefined,
  });

  const { data, error, mutate } = useSWR(
    `${API_URI}/v1/register/${params.deviceId}/status`,
    fetcher,
    { refreshInterval: 5000 }
  );

  const formDeviceName = React.useRef(null);
  const formLocation = React.useRef();

  const { contents, progress } = React.useMemo(() => {
    if (!data) {
      return {
        contents: undefined,
        progress: undefined,
      };
    } else if (data.status === "waiting_for_validation" && state === "not_started") {
      setState("verifying");
      return {
        contents: undefined,
        progress: undefined,
      };
    } else if (data.status === "not_online" && state === "not_started") {
      return {
        contents: <>
          <Container style={{textAlign: "center"}}>
            <Row>
              <Col>
                <h2> Let's Get Started! </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <p> This form will walk you through the setup of your EADS device. </p>
              </Col>
            </Row>
            <Row className="align-items-end justify-content-center">
              <Col md="auto" className="justify-content-center align-items-center">
                <Button onClick={() => setState("started")}>Begin</Button>
              </Col>
            </Row>
          </Container>
        </>,
        progress: 0
      };
    } else if (data.status === "not_registed" && state === "started") {
      return {
        contents: <>
          <Container style={{textAlign: "center"}}>
            <Row>
              <Col>
                <h2> Plug In and Connect Your Device </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <p> First, we will connect your device to the internet. </p>
                <p> After your device is plugged in, the light will start blinking and a WiFi network with the name "EADS Setup" will appear. This may take several minutes the first time this device is powered up. </p>
                <p> Connect to the "EADS Setup" network and enter your network credentials to continue. </p>
                <p> After your device connect to your WiFi network, return to this page to continue the registration process. </p>
              </Col>
            </Row>
          </Container>
        </>,
        progress: 20,
      };
    } else if (data.status === "not_registered_online" && state === "started") {
      return {
        contents: <>
          <Container style={{textAlign: "center"}}>
            <Row>
              <Col>
                <h2> Let's Verify It's You </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <p> Now that the device is online, we will verify you have physical access to the device. This ensures that only you can register your device. </p>
              </Col>
            </Row>
            <Row className="align-items-end justify-content-center">
              <Col md="auto" className="justify-content-center align-items-center">
                <Button onClick={() => {
                  fetch(`${API_URI}/v1/register/${params.deviceId}`, { method: "POST", credentials: "include" })
                    .then(res => res.json())
                    .then(data => {
                      setCookie("register-token", data.token, { path: '/' });
                      setState("verifying");
                      mutate();
                    });

                  // TODO: If this fails we should instruct the user to restart the wifi connect or reach out via email.
                }}>Start Verification</Button>
              </Col>
            </Row>
          </Container>
        </>,
        progress: 40,
      };
    } else if ((data.status === "not_registered" && state === "verifying") || (data.status === "validated" && cookies["register-token"] === undefined)) {
      return {
        contents: <>
          <Container style={{textAlign: "center"}}>
            <Row>
              <Col>
                <h2> Let's Verify It's You </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <p> Oops! Verifcation timed out. </p>
                <p> Try again or contact us at <a href="mailto:seads-group@ucsc.edu">seads-group@ucsc.edu</a> for help. </p>
              </Col>
            </Row>
            <Row className="align-items-end justify-content-center">
              <Col md="auto" className="justify-content-center align-items-center">
                <Button onClick={() => {
                  fetch(`${API_URI}/v1/register/${params.deviceId}`, { method: "POST", credentials: "include" })
                    .then(res => res.json())
                    .then(data => {
                      setCookie("register-token", data.token, { path: '/' });
                      setState("verifying");
                      mutate();
                    });

                  // TODO: If this fails we should instruct the user to restart the wifi connect or reach out via email.
                }}>Start Verification</Button>
              </Col>
            </Row>
          </Container>
        </>,
        progress: 40,
      };
    } else if (data.status === "waiting_for_validation" && state === "verifying") {
      return {
        contents: <>
          <Container style={{textAlign: "center"}}>
            <Row>
              <Col>
                <h2> Let's Verify It's You </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <p> The light on your device will start blinking soon. </p>
                <p> Press the button on your device to continue. </p>
              </Col>
            </Row>
            <Row className="align-items-end justify-content-center">
              <Col md="auto" className="justify-content-center align-items-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Waiting for verification...</span>
                </Spinner>
              </Col>
            </Row>
          </Container>
        </>,
        progress: 60,
      };
    } else if (data.status === "validated") {
      return {
        contents: <>
          <Form validated={validated} onSubmit={(event) => {
              const form = event.currentTarget;

              if(!location.longitude || !location.latitude) {
                event.preventDefault();
                event.stopPropagation();
                return;
              }

              setValidated(true);

              fetch(`${API_URI}/v1/register/${params.deviceId}/complete`, {
                  method: "POST",
                  credentials: "include",
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    name: formDeviceName.current.value,
                    longitude: location.longitude,
                    latitude: location.latitude,
                  })
                })
                .then(() => {
                  mutate();
                });

                event.preventDefault();
                event.stopPropagation();
              // TODO: If this fails we should instruct the user to restart the wifi connect or reach out via email.
            }
          }>
            <Row>
              <Col>
                <h2 style={{textAlign: "center"}}> Some Final Details </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{textAlign: "center"}}> Verification complete! </p>
                <p style={{textAlign: "center"}}> We just need some final details to finish the registration process. </p>
                  <Form.Group className="mb-3" controlId="formDeviceName">
                    <Form.Label>Device Name</Form.Label>
                    <Form.Control required type="text" ref={formDeviceName} placeholder="Enter name" />
                    <Form.Text className="text-muted">
                      Choose a descriptive name for your device. This will be publically visible.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formLocation">
                    <Form.Label>Device Location</Form.Label>
                    <SelectLocation ref={formLocation} onMarkerChange={setLocation} />
                    <Form.Text className="text-muted">
                      Select the location by searching and dragging the marker. This will be publically visible.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      Please select a device location.
                    </Form.Control.Feedback>
                  </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-end justify-content-center">
              <Col md="auto" className="justify-content-center align-items-center">
                <Button type="submit">Finish Registration</Button>
              </Col>
            </Row>
          </Form>
        </>,
        progress: 80,
      };
    } else if (data.status === "registered") {
      return {
        contents: <>
          <Container style={{textAlign: "center"}}>
            <Row>
              <Col>
                <h2> Registration Complete! </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <p> Start to device data by navigating to the device page using the link below. You can also click the "Devices" tab and find your device. </p>
              </Col>
            </Row>
            <Row className="align-items-end justify-content-center">
              <Col md="auto" className="justify-content-center align-items-center">
                <Button as={Link} to={`/devices/${params.deviceId}`}>Go To Device</Button>
              </Col>
            </Row>
          </Container>
        </>,
        progress: 100,
      };
    } else {
      return {
        contents: <>
          <Container style={{textAlign: "center"}}>
            <Row>
              <Col>
                <h2> Oops! </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <p> Something went wrong. Refresh this page and try again or contact us at <a href="mailto:seads-group@ucsc.edu">seads-group@ucsc.edu</a> for help. </p>
              </Col>
            </Row>
          </Container>
        </>,
        progress: 0,
      };

    }
  }, [data, state, cookies, location, validated]);

  if (error) return "An error has occurred.";
  if (!data) return "Loading...";

  return (
    <>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs="12" sm="12" md="12" lg="8">
            <Card>
              <Card.Body>
                { contents }
                <br/>
                <ProgressBar now={progress}/>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
