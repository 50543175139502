import * as React from 'react';
import Map from 'react-map-gl';

import { MAPBOX_ACCESS_TOKEN } from "../constants.js";

import GeocoderControl from './geocoder-control.js';

export default React.forwardRef((props, ref) => {
  return <>
    <Map
      initialViewState={{
        longitude: -100,
        latitude: 40,
        zoom: 3.5
      }}
      style={{height: "50vh"}}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      ref={ref}
    >
      <GeocoderControl mapboxAccessToken={MAPBOX_ACCESS_TOKEN} position="top-left" onMarkerChange={props.onMarkerChange} />
    </Map>
  </>;
});
